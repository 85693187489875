import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { provideTranslocoScope } from '@jsverse/transloco';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { LayoutFooterBlock } from 'app/client/modules/_layout/blocks/footer/footer.block';
import { LayoutHeaderBlock } from 'app/client/modules/_layout/blocks/header/header.block';
import { LayoutSidebarBlock } from 'app/client/modules/_layout/blocks/sidebar/sidebar.block';

import { NotificationModal } from 'app/client/modules/notification/modals/notification/notification.modal';

import { INotification } from 'lc-interfaces';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lc-layout-layout-page',
  standalone: true,
  imports: [RouterOutlet, BaseModule, LayoutFooterBlock, LayoutHeaderBlock, LayoutSidebarBlock],
  providers: [
    provideTranslocoScope({
      scope: 'notification',
      alias: 'notification',
    }),
  ],
  templateUrl: './layout.page.html',
  styleUrls: ['layout.page.scss'],
})
export class LayoutLayoutPage extends BaseComponent implements OnInit {
  public async ngOnInit() {
    const { notification_id } = this.activatedRoute.snapshot.queryParams;

    await firstValueFrom(this.translocoService.events$);

    if (notification_id) {
      this.notificationService.findOneById(notification_id).subscribe(notification => {
        this.openDialog<NotificationModal, INotification>(NotificationModal, {
          title: this.translocoService.translate(`notification.types.${notification.type}`),
          data: notification,
        });
      });
    }
  }
}
