<!--<mat-action-list>-->
@switch (notification.type) {
  @case (ENotificationType.FRIEND_REQUEST) {
    <lc-user-user-block
      [user]="notification.sender"
      [action]="notification.deleted_at ? null : 'accept'"
      (handleUserAction)="handleUserAction($event)"></lc-user-user-block>
  }

  @case (ENotificationType.FRIEND_REQUEST_ACCEPTED) {
    <lc-user-user-block [user]="notification.sender"></lc-user-user-block>
  }

  @case (ENotificationType.PAYMENT_DEPOSIT_PENDING) {
    <lc-payment-payment-block [payment_id]="notification.payload_id"></lc-payment-payment-block>
  }

  @case (ENotificationType.PAYMENT_DEPOSIT_SUCCESS) {
    <lc-payment-payment-block [payment_id]="notification.payload_id"></lc-payment-payment-block>
  }

  @case (ENotificationType.PAYMENT_WITHDRAWAL_PENDING) {
    <lc-payment-payment-block [payment_id]="notification.payload_id"></lc-payment-payment-block>
  }
  @case (ENotificationType.PAYMENT_WITHDRAWAL_SUCCESS) {
    <lc-payment-payment-block [payment_id]="notification.payload_id"></lc-payment-payment-block>
  }
}
<!--</mat-action-list>-->
