import { Component, Input } from '@angular/core';

import { provideTranslocoScope } from '@jsverse/transloco';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { NotificationModal } from 'app/client/modules/notification/modals/notification/notification.modal';

import { ENotificationType, INotification } from 'lc-interfaces';

@Component({
  selector: 'lc-notification-block',
  standalone: true,
  imports: [BaseModule],
  providers: [
    provideTranslocoScope({
      scope: 'notification',
      alias: 'notification'
    }),
  ],
  templateUrl: './notification.block.html',
  styleUrls: ['./notification.block.scss'],
})
export class NotificationBlock extends BaseComponent {
  @Input()
  notification!: INotification;

  public ENotificationType = ENotificationType;

  openNotificationModal() {
    this.openDialog<NotificationModal, INotification>(NotificationModal, {
      title: this.translocoService.translate(`notification.types.${this.notification.type}`),
      data: this.notification,
    });
  }

  remove() {
    this.notificationService.remove(this.notification.id).subscribe(() => (this.notification.deleted_at = new Date()));
  }
}
