import { Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

@Component({
  selector: 'lc-layout-header-block',
  standalone: true,
  imports: [BaseModule],
  templateUrl: './header.block.html',
})
export class LayoutHeaderBlock extends BaseComponent {
  @Input()
  public drawer!: MatDrawer;
}
