import { Component } from '@angular/core';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

@Component({
  selector: 'lc-layout-footer-block',
  standalone: true,
  imports: [BaseModule],
  templateUrl: './footer.block.html',
})
export class LayoutFooterBlock extends BaseComponent {}
