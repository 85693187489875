import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { NotificationBlock } from 'app/client/modules/notification/blocks/notification/notification.block';

import { INotification } from 'lc-interfaces';

@Component({
  selector: 'lc-layout-sidebar-block',
  standalone: true,
  imports: [BaseModule, NotificationBlock],
  templateUrl: './sidebar.block.html',
})
export class LayoutSidebarBlock extends BaseComponent implements OnInit {
  public readonly notifications$: BehaviorSubject<INotification[]> =
    new BehaviorSubject<INotification[]>([]);

  ngOnInit() {
    this.find();
  }

  protected find() {
    this.notificationService
      .find(this.query)
      .subscribe(({ rows, pagination }) => {
        this.notifications$.next([...this.notifications$.getValue(), ...rows]);
        this.pagination = pagination;
      });
  }
}
