<mat-drawer-container autosize>
  <mat-drawer #drawer mode="over" position="end">
    @if (drawer.opened) {
      <mat-toolbar class="d-flex align-items-center justify-content-between">
        <h3>...</h3>

        <button mat-icon-button (click)="drawer.close()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar>

      <div class="mat-drawer-content">
        <lc-layout-sidebar-block></lc-layout-sidebar-block>
      </div>
    }
  </mat-drawer>
</mat-drawer-container>

<div class="layout">
  <lc-layout-header-block [drawer]="drawer"></lc-layout-header-block>

  <div class="mt-3 layout__content">
    <router-outlet></router-outlet>
  </div>

  <lc-layout-footer-block></lc-layout-footer-block>
</div>
