import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import WebApp from '@twa-dev/sdk';

import { Observable } from 'rxjs';

import { environment } from 'app/client/environments/environment';

export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let requestUrl = request.url;

  if (requestUrl.indexOf('@api') !== -1) {
    requestUrl = requestUrl.replace('@api', environment.API_URL);
  }

  request = request.clone({
    url: requestUrl,
    setHeaders: {
      'ngrok-skip-browser-warning': '69420',
    },
  });

  const tma = JSON.parse(sessionStorage.getItem('__telegram__initParams')).tgWebAppData;

  request = request.clone({
    setHeaders: {
      Authorization: `tma ${tma}`,
    },
  });

  return next(request);
};
