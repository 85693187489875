import { ApplicationConfig, inject, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';

import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import WebApp from '@twa-dev/sdk';

import { firstValueFrom } from 'rxjs';

import { environment } from 'app/client/environments/environment';

import { httpInterceptor } from 'app/client/interceptors/http.service';

import { routes } from 'app/client/modules/app.routes';

import { AppService } from 'app/client/services/app.service';
import { TranslocoLoaderService } from 'app/client/services/transloco-loader.service';

import { UserService } from 'app/client/modules/user/user.service';
import { TonService } from 'app/client/modules/payment/ton.service';

export const appInitializer = () => {
  return async () => {
    const translocoService = inject(TranslocoService);

    const appService = inject(AppService);

    const userService = inject(UserService);
    const tonService = inject(TonService);

    const user = await firstValueFrom(userService._me());

    if (user) {
      translocoService.setActiveLang(user.language_code);
      await firstValueFrom(translocoService.load(user.language_code));

      await tonService.initTonConnectUI();

      appService.isLoading$.next(false);
    }
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(appInitializer()),

    provideZoneChangeDetection({ eventCoalescing: true }),

    provideHttpClient(withInterceptors([httpInterceptor])),

    provideAnimations(),
    provideAnimationsAsync(),

    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
      withPreloading(PreloadAllModules)
    ),

    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },

    provideTransloco({
      config: {
        availableLangs: ['en'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.TYPE === 'production',
      },
      loader: TranslocoLoaderService,
    }),

    TonService,
    UserService,
  ],
};
