<mat-list-item>
  <div matListItemIcon (click)="openNotificationModal()">
    @switch (notification.type) {
      @case (ENotificationType.FRIEND_REQUEST) {
        <mat-icon>person_add</mat-icon>
      }

      @case (ENotificationType.FRIEND_REQUEST_ACCEPTED) {
        <mat-icon>person_check</mat-icon>
      }

      @case (ENotificationType.PAYMENT_DEPOSIT_PENDING) {
        <mat-icon>arrow_downward</mat-icon>
      }

      @case (ENotificationType.PAYMENT_DEPOSIT_SUCCESS) {
        <mat-icon>arrow_downward</mat-icon>
      }

      @case (ENotificationType.PAYMENT_WITHDRAWAL_PENDING) {
        <mat-icon>arrow_upward</mat-icon>
      }

      @case (ENotificationType.PAYMENT_WITHDRAWAL_SUCCESS) {
        <mat-icon>arrow_upward</mat-icon>
      }
    }
  </div>

  <div matListItemTitle (click)="openNotificationModal()">
    {{ 'notification.types.' + notification.type | transloco }}
  </div>

  <div matListItemMeta>
    {{ notification.created_at }}

    @if (!notification.deleted_at) {
      <button mat-icon-button (click)="remove()">
        <mat-icon>mark_email_unread</mat-icon>
      </button>
    }
  </div>
</mat-list-item>
