import { bootstrapApplication } from '@angular/platform-browser';

// import eruda from 'eruda';

import WebApp from '@twa-dev/sdk';

import { AppComponent } from 'app/client/modules/app.component';
import { appConfig } from 'app/client/modules/app.config';

try {
  WebApp.ready();

  WebApp.expand();

  WebApp.enableClosingConfirmation();
  WebApp.disableVerticalSwipes();
} catch (error) {}

// eruda.init();

bootstrapApplication(AppComponent, appConfig).catch(error => console.error(error));
