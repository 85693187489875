import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AppService } from 'app/client/services/app.service';

@Component({
  selector: 'lc-root',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe],
  templateUrl: `app.component.html`,
})
export class AppComponent {
  public readonly appService = inject(AppService);
}
