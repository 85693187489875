import { Routes } from '@angular/router';


import { hasAuth } from 'app/client/modules/user/user.service';

import { LayoutLayoutPage } from 'app/client/modules/_layout/pages/layout/layout.page';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },

  {
    path: '',
    canActivate: [hasAuth],

    children: [
      {
        path: '',
        loadChildren: () => import('app/client/modules/_start/start.routes').then(m => m.StartRoutes),
      },

      {
        path: '',
        component: LayoutLayoutPage,
        children: [
          {
            path: '',
            loadChildren: () => import('app/client/modules/home/home.routes').then(m => m.HomeRoutes),
          },

          {
            path: '',
            loadChildren: () => import('app/client/modules/auction/auction.routes').then(m => m.AuctionRoutes),
          },

          {
            path: '',
            loadChildren: () => import('app/client/modules/community/community.routes').then(m => m.CommunityRoutes),
          },

          {
            path: '',
            loadChildren: () => import('app/client/modules/payment/payment.routes').then(m => m.PaymentRoutes),
          },

          {
            path: '',
            loadChildren: () => import('app/client/modules/user/user.routes').then(m => m.UserRoutes),
          },
        ],
      },
    ],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'splash',
  },
];
