import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { provideTranslocoScope } from '@jsverse/transloco';

import { forkJoin } from 'rxjs';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { PaymentPaymentBlock } from 'app/client/modules/payment/blocks/payment/payment.block';
import { UserUserBlock } from 'app/client/modules/user/blocks/user/user.block';

import { ENotificationType, IModal, INotification, IUser } from 'lc-interfaces';

@Component({
  selector: 'lc-notification-modal',
  standalone: true,
  imports: [BaseModule, UserUserBlock, PaymentPaymentBlock],
  providers: [
    provideTranslocoScope({
      scope: 'notification',
      alias: 'notification',
    }),
  ],
  templateUrl: './notification.modal.html',
  styleUrls: ['./notification.modal.scss'],
})
export class NotificationModal extends BaseComponent {
  public readonly ENotificationType = ENotificationType;

  public notification!: INotification;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public modalData: IModal<INotification>
  ) {
    super();

    this.notification = modalData.context.data;
  }

  handleUserAction({ action }: { action: 'request' | 'accept' | 'remove' }) {
    if (action === 'accept') {
      this.accept(this.notification.sender);
    }
  }

  accept(user: IUser) {
    forkJoin([
      this.notificationService.remove(this.notification.id),
      this.communityFriendService.accept(user.id),
    ]).subscribe({
      next: () => {
        this.snackBar.open('Successfully added!');
      },

      error: () => {
        this.snackBar.open('Error!');
      },
    });
  }
}
