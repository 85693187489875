<mat-toolbar class="d-flex justify-content-between">
  <button mat-icon-button [routerLink]="['/home']" routerLinkActive>
    <mat-icon>home</mat-icon>
  </button>

  <button mat-icon-button [routerLink]="['/battles']" routerLinkActive>
    <mat-icon>swords</mat-icon>
  </button>

  <button mat-icon-button [routerLink]="['/auctions']" routerLinkActive>
    <mat-icon>gavel</mat-icon>
  </button>

  <button mat-icon-button [routerLink]="['/community']" routerLinkActive>
    <mat-icon>group</mat-icon>
  </button>
</mat-toolbar>
