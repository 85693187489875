<mat-toolbar *ngIf="userService.user$ | async as user" class="header d-flex align-items-center justify-content-between">
  <div class="header__left">
    {{ user.username || user.first_name }}
  </div>

  <div class="header__center"></div>

  <div class="header__right d-flex align-items-center">
    <div [routerLink]="['/payments']" class="d-flex align-items-center justify-content-center">
      {{ fromNano(user.wallet.ton) }} TON

      <button mat-icon-button>
        <mat-icon>wallet</mat-icon>
      </button>
    </div>

    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>notifications</mat-icon>
    </button>
  </div>
</mat-toolbar>
